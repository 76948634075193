var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-card',{staticClass:"section-container"},[_c('v-card-title',{staticClass:"widget__heading"},[(_vm.isNew)?[_vm._v("Add a Custom Table")]:[_vm._v("Edit Table")]],2),(_vm.modalData)?_c('section',{staticClass:"section-divider"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"mr-12":"","md12":""}},[_c('label',{staticClass:"label",attrs:{"for":"firstName"}},[_vm._v("Competition Name")]),_c('v-text-field',{attrs:{"id":"competitionName","placeholder":"Competition","solo":"","flat":""},model:{value:(_vm.modalData.competitionName),callback:function ($$v) {_vm.$set(_vm.modalData, "competitionName", $$v)},expression:"modalData.competitionName"}}),_c('p',[_c('i',[_vm._v("*Required - Please enter a competition name.")])])],1)],1),_c('ImageUploader',{attrs:{"title":'Competition Icon',"widthOptionEnabled":false,"mediaLibraryID":_vm.modalData.competitionIcon ? _vm.modalData.competitionIcon.mediaLibraryID : null},on:{"update":function($event){return _vm.updateIcon($event)}}}),_c('label',{staticClass:"label",attrs:{"for":"tableType"}},[_vm._v("Table type")]),_c('v-select',{attrs:{"id":"tableType","items":_vm.tableTypeOptions,"outline":"","flat":""},model:{value:(_vm.modalData.tableType),callback:function ($$v) {_vm.$set(_vm.modalData, "tableType", $$v)},expression:"modalData.tableType"}}),(_vm.modalData.tableType === 'text')?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"ml-12":"","md12":""}},[_c('label',{staticClass:"label",attrs:{"for":"lastName"}},[_vm._v("TableData")]),_c('tinymce-editor',{attrs:{"api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
            height: '500px',
            menubar: false,
            plugins: 'table link paste wordcount hr lists textpattern',
            toolbar:
              'undo redo | styleselect | bold italic | \
              link blockquote table | \
              hr | \
              bullist numlist | removeformat',
            resize: false,
            textpattern_patterns: [
              {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
            ],
            style_formats: [
              {title: 'Headers', items: [
                {title: 'Header 2', format: 'h2'},
                {title: 'Header 3', format: 'h3'},
                {title: 'Header 4', format: 'h4'},
                {title: 'Header 5', format: 'h5'},
                {title: 'Header 6', format: 'h6'}
              ]},
              {title: 'Inline', items: [
                {title: 'Bold', icon: 'bold', format: 'bold'},
                {title: 'Italic', icon: 'italic', format: 'italic'},
                {title: 'Underline', icon: 'underline', format: 'underline'},
              ]},
              {title: 'Blocks', items: [
                {title: 'Paragraph', format: 'p'},
              ]},
              {title: 'Alignment', items: [
                {title: 'Left', icon: 'alignleft', format: 'alignleft'},
                {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
                {title: 'Right', icon: 'alignright', format: 'alignright'},
              ]},
            ],
            style_formats_merge: false,
            block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
          }},model:{value:(_vm.modalData.tableData),callback:function ($$v) {_vm.$set(_vm.modalData, "tableData", $$v)},expression:"modalData.tableData"}}),_c('p',[_c('i',[_vm._v("*Required - Please use the wysiwyg editor to create your table.")])])],1)],1):(_vm.modalData.tableType === 'image')?_c('ImageUploader',{attrs:{"title":'Table Image',"widthOptionEnabled":false,"mediaLibraryID":_vm.modalData.tableImage ? _vm.modalData.tableImage.mediaLibraryID : null},on:{"update":function($event){return _vm.updateImage($event)}}}):_vm._e(),_c('v-layout',{staticClass:"actions"},[_c('v-btn',{staticClass:"submit-button error",attrs:{"flat":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancel ")]),(_vm.isNew)?_c('v-btn',{staticClass:"submit-button accent",attrs:{"flat":""},on:{"click":function($event){return _vm.createCustomTable()}}},[_vm._v(" Create ")]):_c('v-btn',{staticClass:"submit-button accent",attrs:{"flat":""},on:{"click":function($event){return _vm.updateCustomTable()}}},[_vm._v(" Update ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }