<template>
    <v-form>
      <v-card class="section-container">
        <v-card-title class="widget__heading">
          <template v-if="isNew">Add a Custom Table</template>
          <template v-else>Edit Table</template>
        </v-card-title>

        <section class="section-divider" v-if="modalData">

          <v-layout row>
            <v-flex mr-12 md12>
              <label class="label" for="firstName">Competition Name</label>
              <v-text-field id="competitionName" placeholder="Competition" solo flat v-model="modalData.competitionName"></v-text-field>
              <p><i>*Required - Please enter a competition name.</i></p>
            </v-flex>
          </v-layout>

          <ImageUploader :title="'Competition Icon'" :widthOptionEnabled="false" :mediaLibraryID="modalData.competitionIcon ? modalData.competitionIcon.mediaLibraryID : null" @update="updateIcon($event)"/>

          <label class="label" for="tableType">Table type</label>
          <v-select id="tableType"
            :items="tableTypeOptions"
            v-model="modalData.tableType"
            outline
            flat>
          </v-select>

          <v-layout row v-if="modalData.tableType === 'text'">
            <v-flex ml-12 md12>
              <label class="label" for="lastName">TableData</label>
              <tinymce-editor v-model="modalData.tableData"
                api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                :init="{
                height: '500px',
                menubar: false,
                plugins: 'table link paste wordcount hr lists textpattern',
                toolbar:
                  'undo redo | styleselect | bold italic | \
                  link blockquote table | \
                  hr | \
                  bullist numlist | removeformat',
                resize: false,
                textpattern_patterns: [
                  {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
                ],
                style_formats: [
                  {title: 'Headers', items: [
                    {title: 'Header 2', format: 'h2'},
                    {title: 'Header 3', format: 'h3'},
                    {title: 'Header 4', format: 'h4'},
                    {title: 'Header 5', format: 'h5'},
                    {title: 'Header 6', format: 'h6'}
                  ]},
                  {title: 'Inline', items: [
                    {title: 'Bold', icon: 'bold', format: 'bold'},
                    {title: 'Italic', icon: 'italic', format: 'italic'},
                    {title: 'Underline', icon: 'underline', format: 'underline'},
                  ]},
                  {title: 'Blocks', items: [
                    {title: 'Paragraph', format: 'p'},
                  ]},
                  {title: 'Alignment', items: [
                    {title: 'Left', icon: 'alignleft', format: 'alignleft'},
                    {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
                    {title: 'Right', icon: 'alignright', format: 'alignright'},
                  ]},
                ],
                style_formats_merge: false,
                block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
              }"></tinymce-editor>
              <p><i>*Required - Please use the wysiwyg editor to create your table.</i></p>
            </v-flex>
          </v-layout>

          <ImageUploader v-else-if="modalData.tableType === 'image'" :title="'Table Image'" :widthOptionEnabled="false" :mediaLibraryID="modalData.tableImage ? modalData.tableImage.mediaLibraryID : null" @update="updateImage($event)"/>

          <v-layout class="actions">
            <v-btn
              flat
              class="submit-button error"
              @click="close()"
            >
            Cancel
            </v-btn>

            <v-btn v-if="isNew"
              flat
              class="submit-button accent"
              @click="createCustomTable()"
            >
              Create
            </v-btn>

            <v-btn v-else
              flat
              class="submit-button accent"
              @click="updateCustomTable()"
            >
              Update
            </v-btn>
          </v-layout>
        </section> 

      </v-card>
    </v-form>
</template>

<script>
import axios from "axios"
import Editor from "@tinymce/tinymce-vue"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "CustomTableEdit",

  components: {
    "tinymce-editor": Editor,
    ImageUploader
  },

  data: () => ({
    api: process.env.VUE_APP_CUSTOMTABLESAPI,
    dialog: true,
    modalData: {
      teamID: null,
      seasonID: null,
      competitionName: null,
      competitionIcon: null,
      tableType: null,
      tableData: null,
      tableImage: {}
    },
    tableTypeOptions: [{text: "Use wysiwyg editor", value: "text"}, {text: "Upload table image", value: "image"}],
    isNew: false
  }),

  mounted() {
    this.modalData.teamID = this.$route.params.teamID
    this.modalData.seasonID = this.$route.params.seasonID

    if (this.$route.params.tableID === "createtable") {
      this.isNew = true
      this.$store.commit("completeLoading")
    } else {
      this.fetchTable()
    }
  },

  methods: {
    close() {
      this.modalData = {
        teamID: null,
        seasonID: null,
        competitionName: null,
        competitionIcon: null,
        tableData: null
      }
      this.$router.push("/team-management/squads")
    },

    createCustomTable() {
      this.$store.commit("startLoading")
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/leaguetables`,
          headers: { "Authorization": jwt },
          data: {
            teamID: this.modalData.teamID,
            seasonID: this.modalData.seasonID,
            competitionName: this.modalData.competitionName,
            competitionIcon: this.modalData.competitionIcon,
            tableType: this.modalData.tableType,
            tableData: this.modalData.tableData,
            tableImage: this.modalData.tableImage

          }
        })
        .then(response => {
          console.log("custom table added", response)
          this.$store.commit("completeLoading");
          this.isNew = false
          this.$router.push("/team-management/squads")
        })
        .catch(e => {
          this.addNew = false
          console.error("Problem adding custom table", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    updateCustomTable() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/leaguetables/${this.modalData.customLeagueTableID}`,
          headers: { "Authorization": jwt },
          data: {
            competitionName: this.modalData.competitionName,
            competitionIcon: this.modalData.competitionIcon,
            tableType: this.modalData.tableType,
            tableData: this.modalData.tableData,
            tableImage: this.modalData.tableImage
          }
        })
        .then(response => {
          console.log("custom table edited", response)
          this.$store.commit("completeLoading");
          this.$router.push("/team-management/squads")
        })
        .catch(e => {
          console.error("Problem editing custom table", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    fetchTable() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/leaguetables/${this.$route.params.tableID}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("getting table data", response)
          this.modalData = response.data.body
          this.$store.commit("completeLoading")
        })
      })
    },

    updateIcon(icon) {
      this.modalData.competitionIcon = icon
    },

    updateImage(image) {
      this.modalData.tableImage = image
    }
  }
}
</script>

<style scoped lang='scss'>

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

</style>
